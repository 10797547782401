







import { Component, Prop } from 'vue-property-decorator'

// mixins
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
// store
import { SingleQuestionResource } from '@/store/types/schema.training'

@Component
export default class TrainingQuestionTextResult extends TestQuestionResultMixin {
  @Prop({ required: true })
  private question!: SingleQuestionResource
}
