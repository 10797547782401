










import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import { SingleQuestionResource } from '@/store/types/schema.training'
import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'

@Component({
  components: {
    QuestionIndicator,
  },
})
export default class TrainingQuestionSingleResult extends Vue {
  @Prop({ required: true })
  private question!: SingleQuestionResource
}
