






















import { Component, Prop } from 'vue-property-decorator'

// mixins
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
// store
import { SingleQuestionResource } from '@/store/types/schema.training'
// utils
import { alphabet, alphabetEng } from '@/utils/constants'

@Component
export default class TrainingQuestionRatioResult extends TestQuestionResultMixin {
  @Prop({ required: true })
  private question!: SingleQuestionResource

  private get isAllEmptyAnswers() {
    return this.question.answers.every(answer => answer.text)
  }

  private get isAllEmptySequences() {
    return this.question.sequences.every(sequence => sequence.text)
  }

  private alphabet = this.subject.value === 6 ? alphabetEng : alphabet
}
