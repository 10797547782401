























































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import FilesList from '@/components/FilesList.vue'
import Player from '@/components/_uikit/Player.vue'
import TrainingQuestionMultipleResult from '@/components/training/TrainingQuestionMultipleResult.vue'
import TrainingQuestionRatioResult from '@/components/training/TrainingQuestionRatioResult.vue'
import TrainingQuestionSequenceResult from '@/components/training/TrainingQuestionSequenceResult.vue'
import TrainingQuestionSingleResult from '@/components/training/TrainingQuestionSingleResult.vue'
import TrainingQuestionTextResult from '@/components/training/TrainingQuestionTextResult.vue'
// store
import { QuestionType, SingleQuestionResource } from '@/store/types/schema.training'

@Component({
  components: {
    FilesList,
    Player,
    TrainingQuestionMultipleResult,
    TrainingQuestionRatioResult,
    TrainingQuestionSequenceResult,
    TrainingQuestionSingleResult,
    TrainingQuestionTextResult,
  },
})
export default class TrainingQuestionMentorView extends Vue {
  @Prop({ required: true })
  private question!: SingleQuestionResource

  private get audioFiles() {
    return this.question.media.filter(media => media.type === 'audio')
  }

  private get hintAudioFiles() {
    return this.question.answerMedia.filter(media => media.type === 'audio')
  }

  private get hintMediaFiles() {
    return this.question.answerMedia.filter(media => media.type !== 'audio')
  }

  private get hintMediaCount() {
    return this.question.answerMedia.length
  }

  private get isQuestionText() {
    return this.question.type === QuestionType.TEXT
  }

  private get isQuestionSingle() {
    return this.question.type === QuestionType.SINGLE
  }

  private get isQuestionMultiple() {
    return this.question.type === QuestionType.MULTIPLE
  }

  private get isQuestionSequence() {
    return this.question.type === QuestionType.SEQUENCE
  }

  private get isQuestionRatio() {
    return this.question.type === QuestionType.RATIO
  }

  private get mediaFiles() {
    return this.question.media.filter(media => media.type !== 'audio')
  }

  private get mediaCount() {
    return this.question.media.length
  }
}
