



































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TrainingQuestionMentorView from '@/components/training/TrainingQuestionMentorView.vue'
// store
import { SingleQuestionResource } from '@/store/types/schema.training'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    Tag,
    TrainingQuestionMentorView,
  },
})
export default class QuestionModal extends Vue {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private question!: SingleQuestionResource | null

  private handleClose() {
    this.$emit('update:visible', false)
    this.$emit('closeModal')
  }
}
