















import { Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import TrainingTable from '@/components/tables/TrainingTable.vue'
// mixins
import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component({
  components: {
    TrainingTable,
  },
})
export default class TrainingList extends PermissionsMixin {
  private metaInfo(): MetaInfo {
    return {
      title: 'Тренажер',
    }
  }
}
