





























































































































































import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import QuestionModal from '@/components/modals/QuestionModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerTrainingModule from '@/store/modules/manager/training'
import {
  SingleQuestionResource, NameValueResource,
  QuestionPaginatorRequest,
  SectionResource, SubjectResource,
  ThemeResource,
} from '@/store/types/schema.training'
// utils
import { tableFooterOptions } from '@/utils/constants'
import { tableOptionsToParams } from '@/utils/functions'

@Component({
  components: {
    Tag,
    Autocomplete,
    QuestionModal,
    Select,
    TableFooter,
    TextInput,
  },
})
export default class TrainingTable extends Mixins(NotifyMixin, PermissionsMixin) {
  private get filter() {
    return ManagerTrainingModule.filter
  }

  private set filter(filter: QuestionPaginatorRequest) {
    ManagerTrainingModule.setFilter(filter)
    this.fetchQuestionsTraining()
  }

  private get headers () {
    return [
      { cellClass: 'cell-width-8', sortable: false, text: '#', value: 'id' },
      { cellClass: 'cell-width-5', sortable: false, text: '', value: 'related' },
      { text: 'Вопрос', value: 'question' },
      { cellClass: 'cell-width-6', text: '№ в КИМе', value: 'kimLine' },
      { text: 'Раздел', value: 'section' },
      { text: 'Тема', value: 'theme' },
      { text: 'Статус', value: 'isPublished' },
      { text: '', value: 'actions' },
    ]
  }

  private get pagination() {
    return ManagerTrainingModule.questions.meta
  }

  private get quesitons() {
    return ManagerTrainingModule.questions.data
  }

  private get subjects() {
    return ManagerTrainingModule.subjects
  }

  private footerOptions = tableFooterOptions

  private hoveredRowIndex = -1

  private kimLines: number[] = []
  private sections: SectionResource[] = []
  private statuses: NameValueResource[] = []
  private themes: ThemeResource[] = []

  private selectedQuestion: SingleQuestionResource | null = null

  private showQuestionModal = false

  private mounted() {
    // Запрашиваем справочник предметов для фильтра
    if (!this.subjects.length) {
      ManagerTrainingModule.fetchSubjects()
        .catch(this.notifyError)
    }
    // Запрашиваем справочники КИМов, Разделов, Тем, Статусов
    this.fetchSections(this.filter.subjectId)
    this.fetchKIM(this.filter.subjectId)
    if (this.filter.sectionId)
      this.fetchThemes(this.filter.sectionId)
    this.fetchStatuses()
  }

  private clearFilter() {
    this.filter = {
      ...this.filter,
      kimLine: undefined,
      sectionId: undefined,
      themeId: undefined,
      search: undefined,
      status: undefined,
    }
  }

  @Bind
  @Debounce(300)
  private fetchQuestionsTraining() {
    ManagerTrainingModule.fetchQuestionsTraining(tableOptionsToParams(this.filter))
      .catch(this.notifyError)
  }

  private fetchKIM(subjectId: number) {
    ManagerTrainingModule.fetchKIM(subjectId)
      .then(response => {
        this.kimLines = response
      })
      .catch(this.notifyError)
  }

  private fetchSections(subjectId: number) {
    ManagerTrainingModule.fetchSections({ subjectId })
      .then(response => {
        this.sections = response
      })
      .catch(this.notifyError)
  }

  private fetchStatuses() {
    ManagerTrainingModule.fetchStatuses()
      .then(response => {
        this.statuses = response
      })
      .catch(this.notifyError)
  }

  private fetchThemes(sectionId: number) {
    ManagerTrainingModule.fetchThemes({ sectionIds: [sectionId] })
      .then(response => {
        this.themes = response
      })
      .catch(this.notifyError)
  }

  private handleHoverOver(id: number) {
    this.hoveredRowIndex = this.quesitons.findIndex(_item => _item.id === id)
  }

  private handleTouch(id: number) {
    this.hoveredRowIndex = -1
    this.hoveredRowIndex = this.quesitons.findIndex(_item => _item.id === id)
  }

  private handleFilter(field: keyof QuestionPaginatorRequest, value: never) {
    if (field === 'subjectId') {
      this.filter.kimLine = undefined
      this.filter.sectionId = undefined
      this.filter.themeId = undefined
      this.themes = []
      this.fetchKIM(value)
      this.fetchSections(value)
    }

    if (field === 'sectionId') {
      this.filter.themeId = undefined
      if (value)
        this.fetchThemes(value)
      else
        this.themes = []
    }

    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleRowClick(item: SubjectResource) {
    ManagerTrainingModule.fetchQuestion(item.id)
      .then(response => {
        this.selectedQuestion = response
        this.showQuestionModal = true
      })
      .catch(this.notifyError)
  }
}
